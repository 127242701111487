import { nextTick } from 'vue';
import { useRouter } from 'vue-router';

export const useNavigate = () => {
  // this.$refs.ref_meets.
  const router = useRouter();

  // eslint-disable-next-line @typescript-eslint/ban-types
  const navigate = (url: string, scope?: 'router' | 'window', query?: {}) => {
    if (scope === 'window') {
      return window.open(url);
    }

    router
      .push({ path: url, query })
      .then(() => {
        nextTick(() => {
          window.scroll({ top: 0 });
        });
      })
      .catch(() => {
        //
      });
  };
  return {
    navigate
  };
};
