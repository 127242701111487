import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import MainLayout from '@/layout/Index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: MainLayout,
    redirect: '/top',
    children: [
      {
        path: 'top',
        component: () => import('@/views/DashBoard.vue'),
        name: 'DashBoard',
        meta: {
          roles: []
        }
      },
      {
        path: 'rent',
        component: () => import('@/views/Renting.vue'),
        name: 'Renting',
        meta: {
          roles: []
        }
      },
      {
        path: 'buy',
        component: () => import('@/views/Trading.vue'),
        name: 'Trading',
        meta: {
          roles: []
        }
      },
      {
        path: 'owner',
        component: () => import('@/views/Owner.vue'),
        name: 'Owner',
        meta: {
          roles: []
        }
      },
      {
        path: 'asset-management',
        component: () => import('@/views/AssetManagement.vue'),
        name: 'AssetManagement',
        meta: {
          roles: []
        }
      },
      {
        path: 'corporate',
        component: () => import('@/views/CompanyProfile.vue'),
        name: 'CompanyProfile',
        meta: {
          roles: []
        }
      },
      {
        path: 'shop',
        component: () => import('@/views/ShopList.vue'),
        name: 'ShopList',
        meta: {
          roles: []
        }
      },
      {
        path: 'recruit',
        component: () => import('@/views/Recruit.vue'),
        name: 'Recruit',
        meta: {
          roles: []
        }
      },
      {
        path: 'contact',
        component: () => import('@/views/Contact/Index.vue'),
        name: 'ContactIndex',
        meta: {
          roles: []
        },
        children: [
          {
            path: '',
            name: 'Contact',
            component: () => import('@/views/Contact/Contact.vue')
          },
          {
            path: 'owner',
            name: 'ContactOwner',
            component: () => import('@/views/Contact/ContactOwner.vue')
          },
          {
            path: 'request',
            name: 'ContactOther',
            component: () => import('@/views/Contact/ContactOther.vue')
          },
          {
            path: 'buy',
            name: 'ContactBuy',
            component: () => import('@/views/Contact/ContactBuy.vue')
          },
          {
            path: 'cancel',
            name: 'ContactCancel',
            component: () => import('@/views/Contact/ContactCancel.vue')
          },
          {
            path: 'monthly',
            name: 'ContactCMonthly',
            component: () => import('@/views/Contact/ContactMonthly.vue')
          },
          {
            path: 'monthlyhoujin',
            name: 'ContactCMonthlyHoujin',
            component: () => import('@/views/Contact/ContactMonthlyHoujin.vue')
          }
        ]
      },
      {
        path: 'privacy',
        component: () => import('@/views/PrivacyPolicy.vue'),
        name: 'PrivacyPolicy',
        meta: {
          roles: []
        }
      },
      {
        path: 'news',
        component: () => import('@/views/New/index.vue'),
        name: 'NewsIndex',
        meta: {
          roles: []
        },
        children: [
          {
            path: '',
            name: 'List',
            component: () => import('@/views/New/List.vue')
          },
          {
            path: 'detail',
            name: 'Detail',
            component: () => import('@/views/New/Detail.vue')
          }
        ]
      },
      {
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/NotFound.vue'),
        name: 'NotFound'
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/NotFound.vue'),
    name: 'NotFound'
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
