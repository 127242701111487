
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useNavigate } from '@/composable';

interface NavItem {
  name: string;
  text: string;
  active: boolean;
  url: string;
}

export default defineComponent({
  name: 'NavBar',
  props: {},
  setup() {
    const route = useRoute();

    const { navigate } = useNavigate();

    const url = computed(() => route.path);

    const nav = reactive<NavItem[]>([
      {
        name: 'owner',
        text: 'オーナー様',
        active: false,
        url: '/owner'
      },
      {
        name: 'borrow',
        text: '借りたい方',
        active: false,
        url: '/rent'
      },
      {
        name: 'buy/sell',
        text: '購入・売却',
        active: false,
        url: '/buy'
      },
      {
        name: 'utilization',
        text: '不動産資産活用',
        active: false,
        url: '/asset-management'
      },
      {
        name: 'About us',
        text: '会社情報',
        active: false,
        url: '/corporate'
      },
      {
        name: 'information',
        text: '採用情報',
        active: false,
        url: '/recruit'
      }
    ]);
    const isShowMenu = ref(false);

    const onCall = (mobile: string) => {
      window.open(`tel:${mobile}`, '_self');
    };

    const goToContact = () => {
      navigate('/contact');
    };

    const goToCancelContact = () => {
      navigate('/contact/cancel');
    };

    const goToHome = () => {
      navigate('/top');
    };

    watch(
      url,
      (val) => {
        if (val) {
          isShowMenu.value = false;
          nav.forEach((el) => (el.active = false));
          const navIndex = nav.findIndex((el) => el.url === val);
          if (navIndex !== -1) {
            nav[navIndex].active = true;
          }
        }
      },
      {
        immediate: true
      }
    );

    return {
      nav,
      isShowMenu,
      navigate,
      goToContact,
      goToCancelContact,
      goToHome,
      onCall
    };
  }
});
