import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueGtag from 'vue-gtag';

import '@/styles/index.scss';

createApp(App)
  .use(router)
  .use(VueReCaptcha, {
    siteKey: '6Lc0JQ8jAAAAAAuCIsOlJlUgWA0UScKB-co7vqqT',
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true
    }
  })
  .use(VueGtag, {
    config: {
      id: 'UA-68080814-1'
    }
  })
  .mount('#app');
