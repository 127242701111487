
import { defineComponent, ref, watch } from 'vue';
import { useNavigate } from '@/composable';
import { useRoute } from 'vue-router';

interface NavItem {
  text: string;
  url: string;
}

export default defineComponent({
  name: 'Footer',
  props: {
    msg: String
  },
  setup() {
    const { navigate } = useNavigate();
    const route = useRoute();
    const hideRoute = ref<boolean>(false);

    const relativeItems = ref<NavItem[]>([
      {
        text: 'オーナー様',
        url: '/owner'
      },
      {
        text: '会社情報',
        url: '/corporate'
      },
      {
        text: 'ニュース',
        url: '/news'
      },
      {
        text: '借りたい方',
        url: '/rent'
      },
      {
        text: '店舗一覧',
        url: '/shop'
      },
      {
        text: '個人情報の取り扱いについて',
        url: '/privacy'
      },
      {
        text: '購入・売却',
        url: '/buy'
      },
      {
        text: '採用情報',
        url: '/recruit'
      },
      {
        text: 'お問い合わせ',
        url: '/contact'
      },
      {
        text: '不動産資産活用',
        url: '/asset-management'
      }
    ]);
    const scrollToTop = () => {
      window.scroll({ top: 0, behavior: 'smooth' });
    };

    const goToContact = () => {
      navigate('/contact');
    };

    const goToCancelContact = () => {
      navigate('/contact/cancel');
    };

    const onCall = (mobile: string) => {
      window.open(`tel:${mobile}`, '_self');
    };

    watch(
      () => route.path,
      () => {
        if (
          route.path.split('/').includes('news') ||
          route.path.split('/').includes('contact')
        ) {
          hideRoute.value = true;
        } else {
          hideRoute.value = false;
        }
      },
      { deep: true, immediate: true }
    );

    return {
      relativeItems,
      scrollToTop,
      navigate,
      goToContact,
      goToCancelContact,
      onCall,
      hideRoute
    };
  }
});
