export const useEmail = () => {
  // eslint-disable-next-line
  const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const validEmail = (email: string) => {
    // eslint-disable-next-line
    // @ts-ignore
    if (email.match(mailFormat)) {
      return true;
    } else {
      return false;
    }
  };

  return {
    validEmail
  };
};
