import { onBeforeUnmount, onMounted } from 'vue';

export const useClickOutSide = (
  elementRef: { value: HTMLElement },
  callbackFunction: () => {
    //
  }
) => {
  if (!elementRef) return;

  const listener = (e: Event) => {
    if (
      e.target === elementRef.value ||
      e.composedPath().includes(elementRef.value)
    ) {
      // click inside
      return;
    }
    if (typeof callbackFunction === 'function') {
      callbackFunction();
    }
  };

  onMounted(() => {
    window.addEventListener('click', listener);
  });
  onBeforeUnmount(() => {
    window.removeEventListener('click', listener);
  });
};
